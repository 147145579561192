/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage } from 'react-intl';
import scrolltop from 'scrolltop';
import throttle from 'lodash/throttle';

import { connect } from 'react-redux';
import { getUser } from '../../store/selectors/user';
import { toggleContrast } from '../../store/actions';

// eslint-disable-next-line import/no-unresolved
import LanguageSwitcher from './LanguageSwitcher';
import LangLink from '../LinkWithLang';
import getMessage from '../../utils/getMessage';
import { isAdmin } from '../../utils/user';
import UserMenu from '../user/UserMenu';
import '../../views/styles.css';
import useWindowSize from '../../common/useWindowSize';
import { logout } from '../../utils/loginUtils';

const Header = ({ user, language, history, toggleContrast, location }) => {
  const [handleNavFix, setHandleNavFix] = useState()
  const [navbarToggle, setNavbarToggle] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const _handleNavFix = throttle(() => {
        const scrollY = scrolltop();
        if (scrollY > 45) {
          document.body.classList.add('nav-fixed');
        } else {
          document.body.classList.remove('nav-fixed');
        }
      }, 25);
      window.addEventListener('scroll', _handleNavFix, false);
      window.onmessage = function democracySearchMessageEvent(event) {
        if (event.origin === 'https://haku.demokratia.fi') {
          const newHeight = event.data;
          if (newHeight) {
            window.document.getElementById(
              'super-search-iframe',
            ).style.height = newHeight;
          }
        }
      };
      setHandleNavFix(_handleNavFix);
    }
  }, [])

  useEffect(() => {
    return (
      () => {
        if (handleNavFix) {
          window.removeEventListener('scroll', _handleNavFix);
        }
      }
    )
  }, [])


  // eslint-disable-next-line class-methods-use-this
  const getServiceItems = () => {
    return [
      <ul className="nav navbar-nav">
        <li className="nav-item">
          <a href="https://demokratia.fi" target="blank">
            demokratia.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://kuntalaisaloite.fi" target="blank">
            kuntalaisaloite.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://kansalaisaloite.fi" target="blank">
            kansalaisaloite.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://nuortenideat.fi" target="blank">
            nuortenideat.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://otakantaa.fi" target="blank">
            otakantaa.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://lausuntopalvelu.fi" target="blank">
            lausuntopalvelu.fi
          </a>
        </li>

        <li className="nav-item">
          <a href="https://vaalit.fi" target="blank">
            vaalit.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://puoluerekisteri.fi" target="blank">
            puoluerekisteri.fi
          </a>
        </li>
      </ul>,
    ];
  }

  const { width } = useWindowSize();
  const bootstrapMdBreakPoint = 992;
  const aboveBootstrapMdBreakPoint = width > bootstrapMdBreakPoint;

  const getUserItems = () => {
    if (!user) {
      return [
        getNavItem('loginButton','/auth/login', 'digi-button', 'loginButton'),
      ];
    }
    if (aboveBootstrapMdBreakPoint) {
      return [
        <UserMenu 
          user={user} 
          language={language} 
          key="userMenu"
          getNavItem={getNavItem}
        />,
      ];
    } else {
      return [
        getNavItem('ownProfile', '/user-info', undefined, 'ownProfileItemMobile'),
        <NavItem
          key={'logoutButtonMobile'}
          className="nav-item nav-link"
          role="button"
          style={{ margin: '10px 15px' }}
          aria-label={getMessage('logout')}
          onClick={() => logout(language)}
        >
          <div className="nav-button">
            <FormattedMessage
              id="logout"
            />
          </div>
        </NavItem>
      ]
    }
    
  }

  const getNavItem = (id, url, linkClassName, key) => {
    const active = history && history.location.pathname === url;
    if (url) {
      const linkClassNameResolved = "nav-link"+(!!linkClassName ? ` ${linkClassName}` : '')
      return (
        <LinkContainer to={url + '?lang=' + language} key={key}>
          <NavItem className={`nav-item ${active ? 'active' : ''}`} 
            href="#" 
            aria-current={active?'page':null} // current:page for screen readers
          >
            <div className={linkClassNameResolved}>
              <FormattedMessage id={id + 'HeaderText'} />
            </div>
          </NavItem>
        </LinkContainer>
      );
    }
    return <li className={`nav-item ${active && 'active'}`} key={key}>{navLink}</li>;
  }

  const userItems = getUserItems();
  const digiraatiLogo = `/assets/images/digiraati-logo-${language === 'sv' ? 'sv' : 'fi'}.png`

  return (
    <div>
      <div className="super-search-placeholder">
        <iframe
          id="super-search-iframe"
          src="https://haku.demokratia.fi/#/?lan=fi&amp;site=otakantaa.fi"
        />
      </div>
      <div className="nav-fixed-placeholder">
        {/*Takes the space when nav turns position:fixed*/}
      </div>
      <FormattedMessage id="headerPagesNavLabel">
        {headerPagesNavLabel => (
          <Navbar
            default
            collapseOnSelect
            className="navbar-primary"
            aria-label={headerPagesNavLabel}
            onToggle={(expanded) => setNavbarToggle(expanded)}
          >
            <div className="background-container" aria-hidden="true">
              <div className="navbar-primary__ball" />
            </div>
            <Navbar.Header>
              <Navbar.Brand>
                <LangLink to={{ path: '/' }}>
                  <img
                    src={digiraatiLogo}
                    height="50"
                    alt={getMessage('digiraatiHomepage')}
                  />
                </LangLink>
                
              </Navbar.Brand>
              <FormattedMessage id={navbarToggle ? "mainMenuClose" : "mainMenuOpen"}>
                {mainMenu => <Navbar.Toggle aria-label={mainMenu} className={navbarToggle ? 'navbar-toggle--open' : ''} />}
              </FormattedMessage>
            </Navbar.Header>
            <Navbar.Collapse>
              {user && !aboveBootstrapMdBreakPoint && (
                <Navbar.Text className="mobileOnlyMenuUserName" aria-label={getMessage('userInfoLabel')}>
                  {user.username}
                </Navbar.Text>)}
              <Nav>
                {isAdmin(user) && (getNavItem('createHearing', '/hearing/new', 'nav-button'))}
                
                {getNavItem('info', '/info')}
                {getNavItem('participate', '/participate')}
                
                {getNavItem('hearingJury', '/hearings/list')}
                
                {userItems}
                <LanguageSwitcher currentLanguage={language} />

              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
      </FormattedMessage>
    </div>
  );
}

Header.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: PropTypes.func,
  history: PropTypes.object,
  language: PropTypes.string,
  user: PropTypes.object,
  toggleContrast: PropTypes.func,
};

Header.contextTypes = {
  history: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  toggleContrast: () => dispatch(toggleContrast()),
});

export default withRouter(
  connect(
    state => ({
      user: getUser(state), // User dropdown requires this state
      language: state.language, // Language switch requires this state
      router: state.router, // Navigation activity requires this state
    }),
    mapDispatchToProps,
  )(Header),
);
