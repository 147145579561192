import { createSelector } from 'reselect';

export const getUser = createSelector(
  [(state) => state.user.data], 
  user => user,
  {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

export const getToken = (state) => state.apitoken;
