import { useState } from "react";
import getMessage from "../../utils/getMessage";
import LoadSpinnerContainer from "../LoadSpinnerContainer";
import config from "../../config";
import { FormattedMessage } from "react-intl";
import { localizedNotifyError, localizedNotifySuccess } from "../../utils/notify";
import { turnResponseErrorStringToLocalizationKey } from "../../utils/validation";

const UserUpdateEmail = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changeRequestSent, setChangeRequestSent] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const onSubmitEmail = async () => {
    setIsSubmitting(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${config.apiBaseUrl}/v1/users/emailchange/`, {
      method: "POST",
      headers: {
        Authorization: "Token " + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: newEmail,
      }),
    });
    if (response.ok) {
      localizedNotifySuccess('userUpdateEmailVerificationSent');
      setChangeRequestSent(true);
      setNewEmail('');
    } else {
      try {
        const data = await response.json();
        if (data.status) {
          const errorKey = turnResponseErrorStringToLocalizationKey(data.status, 'userUpdateEmailError');
          localizedNotifyError(errorKey);
        } else {
          localizedNotifyError('userUpdateEmailFailed');
        }
      } catch(e) {
        localizedNotifyError('userUpdateEmailFailed');
      }
    }
    setIsSubmitting(false);
  }

  return (
    <div className="hds-form hds-form--login" style={{paddingTop: '0'}}>
      <h3><FormattedMessage id="userUpdateEmail" /></h3>
      
      {!changeRequestSent && <div>
      <p><FormattedMessage id="userUpdateEmailDescription" /></p>
        <fieldset className="hds-fieldset">
            <div className="hds-text-input">
              <label htmlFor="email">
                <FormattedMessage id="userUpdateEmailNewEmail" />
              </label>
              <div className="hds-text-input__input-wrapper">
                <input
                  required
                  id="email"
                  name="email"
                  className="form-control"
                  type="text"
                  onChange={(e) => {setNewEmail(e.target.value)}}
                />
              </div>
            </div>
          </fieldset>
          <div style={{display: 'flex', justifyContent: 'end'}}>
            <LoadSpinnerContainer isLoading={isSubmitting}>
              <button type="button" onClick={onSubmitEmail} className="digi-button" disabled={isSubmitting}>
                {getMessage('submit')}
              </button>
            </LoadSpinnerContainer>
          </div>
      </div>}
      {changeRequestSent && (
        <div>
          <p><FormattedMessage id='userUpdateEmailVerificationSent' /></p>
        </div>)
      }
    </div>
  )
}

export default UserUpdateEmail;