import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import { TabContext, TabPanel } from '@mui/lab';
import { StyledTabs } from './UserSummaryPanel';
import DigiraatiTabButton from './DigiraatiTabButton';
import getMessage from '../../utils/getMessage';
import UserUpdateEmail from './UserUpdateEmail';
import UserUpdateUsername from './UserUpdateUsername';

const UserUpdateModal = ({ isOpen, close }) => {
  const [selectedTab, setSelectedTab] = useState("userUpdateUsername");

  const handleTabChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Modal show={isOpen} onHide={() => close()} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title><FormattedMessage id="userUpdateInfoTitle"/></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'white'}}>
        <TabContext value={selectedTab}>
          <StyledTabs value={selectedTab}>
            <DigiraatiTabButton 
              tabLabel={getMessage("userUpdateUsername")}
              isSelected={selectedTab === "userUpdateUsername"}
              value="userUpdateUsername"
              handleChange={handleTabChange}
            />
            <DigiraatiTabButton 
              tabLabel={getMessage("userUpdateEmail")}
              isSelected={selectedTab === "userUpdateEmail"}
              value="userUpdateEmail"
              handleChange={handleTabChange}
            />
          </StyledTabs>
          <TabPanel
            value="userUpdateUsername"
          >
            <UserUpdateUsername value="userUpdateUsername" index="userUpdateUsername" />
          </TabPanel>
          <TabPanel
            value="userUpdateEmail"
          >
            <UserUpdateEmail value="userUpdateEmail" index="userUpdateEmail" />
          </TabPanel>
          
        </TabContext>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="digi-button" onClick={() => { close(); }}>
          <FormattedMessage id="back"/>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

UserUpdateModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};

export default UserUpdateModal;
