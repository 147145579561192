import React from 'react';
import PropTypes from 'prop-types';
import Label from 'react-bootstrap/lib/Label';
import getAttr from '../utils/getAttr';
import Link from './LinkWithLang';
import { injectIntl, intlShape } from 'react-intl';

export const Labels = ({className, intl, labels, language}) => {

  const labelToHTML = (label) => (
    <Link
      to={{
        path: '/hearings/list',
        search: `?label=${label.id}&lang=${language}`,
        state: { filteredByLabelLink: true }
      }}
      key={label.id || label}
    >
      <label>#{getAttr(label.label, language)}</label>{' '}
    </Link>
  );

  const ariaLabel = intl.formatMessage({id: 'labelListTagsTitle'});

  return (
    <div className={className} aria-label={ariaLabel}>
      {labels.map((label) => labelToHTML(label))}
    </div>
  );
  
}

Labels.propTypes = {
  intl: intlShape,
  labels: PropTypes.array,
  className: PropTypes.string,
  language: PropTypes.string
};

export default injectIntl(Labels);
