import PropTypes from 'prop-types';
import { getUser } from '../../store/selectors/user';
import { FormattedMessage } from 'react-intl';
import getMessage from '../../utils/getMessage';
import React from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions';
import { getHearingURL } from '../../utils/hearing';
import { withRouter } from 'react-router-dom';
import LinkWithLang from '../LinkWithLang';

const HearingJoinRequestInfo = ({ 
  hearing,
  user,
  history,
  language,
}) => {
  const onClickedWantToParticipate = async () => {
    if (!user) {
      window.sessionStorage.setItem('wantToParticipateInHearing', hearing.id)
      history.push(`/auth/login?lang=${language}`)
      return;
    }

    history.push(getHearingURL(hearing)+`/?lang=${language}`);
  };
  
  if (hearing.can_access_detail) {
    return (
      <div className="hearing-join-request-button-wrap">
        <LinkWithLang className="digi-button digi-button-green" to={{ path: getHearingURL(hearing) }}>
          <FormattedMessage id="goToHearing" />
        </LinkWithLang>
      </div>
    )
  }

  const joinRequestOption =
    <div className="hearing-join-request-button-wrap">
      {hearing.user_has_active_join_request
        ? <FormattedMessage id="hearingJoinRequestIsPending" />
        : (<button type='button' className='digi-button' onClick={onClickedWantToParticipate}>
        {getMessage('iWantToParticipateInHearing')}
      </button>)
      }
    </div>;
  const showJoinRequestInfo = !hearing.can_access_detail && !hearing.closed;
  return (<>
    {showJoinRequestInfo && joinRequestOption}
  </>);
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  language: state.language,
});

HearingJoinRequestInfo.propTypes = {
  hearing: PropTypes.object.isRequired,
  user: PropTypes.object,
  fetchAllHearings: PropTypes.func,
  listId: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  fetchAllHearings: (list) => dispatch(Actions.fetchHearingList(list, '/v1/hearing', {}))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HearingJoinRequestInfo));


