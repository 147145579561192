import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getUser } from '../../store/selectors/user';
import config from '../../config';

import 'hds-core';
import 'hds-core/lib/components/text-input/text-input.css';
import 'hds-core/lib/components/button/button.css';
import './styles.css';
import getMessage from '../../utils/getMessage';
import { object, ref, string } from 'yup';
import { Field, Form, Formik } from 'formik';
import { FormHelperText } from '@mui/material';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import DigiraatiPrivacyCheckbox, { DigiraatiPrivacyCheckboxValidationSchema } from './Fields/PrivacyCheckbox';
import DigiraatiUsernameInput, { DigiraatiUsernameInputValidationSchema } from './Fields/UsernameInput';
import LoadSpinnerContainer from '../../components/LoadSpinnerContainer';

export const StyledForm = styled(Form)`
    label {
        margin-bottom: 0;
        font: normal normal normal 18px PT Sans;
    }

    p {
        font: normal normal normal 18px PT Sans;
    }
`;

const Registration = ({ history, language }) => {
  const [error, setError] = useState('');

  const onSubmit = async (_event, values, setSubmitting, isValid, setTouched, setFieldError) => {
    if (!isValid) {
      setTouched(values, true);
      return;
    }
    setSubmitting(true);
    setError('');

    try {
      const queryParams = window.location.search;
      const result = await fetch(`${config.apiBaseUrl}/v1/auth/registration/${queryParams}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          birth_year: values.birth_year,
          home_municipality: values.home_municipality,
          username: values.username,
          password1: values.password1,
          password2: values.password2,
          email: values.email,
          gender: values.gender,
          native_language: values.native_language,
          main_activity: values.main_activity,
          is_approved_privacy: values.is_approved_privacy,
        }),
      });
      const response = await result.json();
      if (result.status === 201) {
        history.push({
          pathname: '/auth/registration/success',
          search: `?lang=${language}`,
        });
      } else {
        // Set default error message.
        let errorMessage = getMessage('registrationFailed');
        // Back-end returns error messages on two different arrays depending
        // on the error type: non_field_errors or password1.
        // Handle the non_field_errors type first.
        if ('non_field_errors' in response) {
          if (
            response.non_field_errors[0] ===
            'The two password fields didn\'t match.'
          ) {
            errorMessage = getMessage('registrationFailedPasswordMismatch');
          } else if (
            response.non_field_errors[0] ===
            'The password is too similar to the username.'
          ) {
            errorMessage = getMessage(
              'registrationFailedPasswordMatchesUsername',
            );
          } else if (
            response.non_field_errors[0] ===
            'Privacy not approved'
          ) {
            errorMessage = getMessage(
              'registrationFailedPrivacyNotApproved',
            );
          }
        } else if ('username' in response) {
          if (response.username.includes('A user with that username already exists.')) {
            errorMessage = getMessage('registrationFailedUsernameAlreadyExists');
            setFieldError('username', errorMessage)
          }
        } else if ('email' in response) {
          const responseErrorMessages = response.email.join();
          if (responseErrorMessages === 'Enter a valid email address.') {
            errorMessage = getMessage('registrationFailedInvalidEmailAddress');
          } else if (
            responseErrorMessages ===
            'A user is already registered with this e-mail address.'
          ) {
            errorMessage = getMessage(
              'registrationFailedEmailAddressAlreadyExists',
            );
          }
          setFieldError('email', errorMessage)
        } else if ('password1' in response) {
          const responseErrorMessages = response.password1.join();
          if (
            responseErrorMessages ===
            'Password must be a minimum of 6 characters.'
          ) {
            errorMessage = getMessage('registrationFailedShortPassword');
            setFieldError('password1', errorMessage)
          }
        }
        setError(errorMessage);
        setSubmitting(false);
      }
    } catch (catchError) {
      setSubmitting(false);
      setError(getMessage('registrationFailed'));
      throw new Error('Couldn\'t log in.');
    }
  };

  const formValidationSchema = object().shape({
    ...DigiraatiUsernameInputValidationSchema,
    password1: string().required('required'),
    password2: string()
      .oneOf([ref('password1')], 'registrationFailedPasswordMismatch')
      .required('required'),
    email: string().email('registrationFailedInvalidEmailAddress').required('required'),
    ...DigiraatiPrivacyCheckboxValidationSchema,
  });


  return (
    <div>
      <Helmet title={getMessage('signup')} />
      <Formik
        initialValues={{
          username: '',
          password1: '',
          password2: '',
          email: '',
          birth_year: '',
          home_municipality: '',
          gender: '',
          native_language: '',
          is_approved_privacy: false,
        }}
        validationSchema={formValidationSchema}
      >
        {({
            touched,
            errors,
            values,
            setSubmitting,
            isSubmitting,
            isValid,
            setTouched,
            setFieldError,
          }) => {
          return (
            <StyledForm autoComplete="off" className="hds-form hds-form--login">
              <h3>{getMessage('signup')}</h3>
              <p>{getMessage('allFieldsAreRequired')}</p>
              <div className="hds-card">
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="hds-fieldset">
                  <Field
                    name="username"
                    required
                    disabled={isSubmitting}
                    component={DigiraatiUsernameInput} 
                  />
                  <div className="hds-text-input">
                    <label htmlFor="password1">{getMessage('password')}*</label>
                    <Field
                      id="password1"
                      required
                      name="password1"
                      class="form-control"
                      type="password"
                      autocomplete="new-password"
                      disabled={isSubmitting}
                      aria-describedby="new_password_input_helpertext"
                    />
                    <FormHelperText
                      id="new_password_input_helpertext"
                      error={Boolean(touched.password1 && errors.password1)}
                    >
                      {touched.password1 && errors.password1
                        ? getMessage(errors.password1)
                        : ' '}
                    </FormHelperText>
                  </div>
                  <div className="hds-text-input">
                    <label htmlFor="password2">{getMessage('passwordReenter')}*</label>
                    <Field
                      id="password2"
                      required
                      name="password2"
                      class="form-control"
                      type="password"
                      autocomplete="new-password"
                      disabled={isSubmitting}
                      aria-describedby="password2_input_helpertext"
                    />
                    <FormHelperText
                      id="password2_input_helpertext"
                      error={Boolean(touched.password2 && errors.password2)}
                    >
                      {touched.password2 && errors.password2
                        ? getMessage(errors.password2)
                        : ' '}
                    </FormHelperText>
                  </div>
                  <div className="hds-text-input">
                    <label htmlFor="email">{getMessage('emailAddress')}*</label>
                    <Field
                      id="email"
                      required
                      name="email"
                      class="form-control"
                      type="email"
                      autocomplete="off"
                      disabled={isSubmitting}
                      aria-describedby="email_input_helpertext"
                    />
                    <FormHelperText
                      id="email_input_helpertext"
                      error={Boolean(touched.email && errors.email)}
                    >
                      {touched.email && errors.email
                        ? getMessage(errors.email)
                        : ' '}
                    </FormHelperText>
                  </div>
                  <h4>{getMessage('terms')}</h4>
                  <Field
                    name="is_approved_privacy"
                    component={DigiraatiPrivacyCheckbox}
                  />
                  <div style={{display: 'flex', justifyContent: 'end'}}>
                    <LoadSpinnerContainer isLoading={isSubmitting} >
                      <button type="submit" className="digi-button"
                              disabled={isSubmitting}
                              onClick={
                                (event) => onSubmit(
                                  event, 
                                  values, 
                                  setSubmitting, 
                                  isValid, 
                                  setTouched, 
                                  setFieldError)}
                      >
                        {getMessage('signup')}
                      </button>
                    </LoadSpinnerContainer>
                  </div>
                </div>
              </div>
            </StyledForm>
          );
        }}
      </Formik>
    </div>
  );

};

Registration.propTypes = {
  history: PropTypes.object,
  language: PropTypes.object,
};

const mapDispatchToProps = () => ({});

export default withRouter(
  connect(
    state => ({
      user: getUser(state), // User dropdown requires this state
      language: state.language, // Language switch requires this state
      router: state.router, // Navigation activity requires this state
    }),
    mapDispatchToProps,
  )(Registration),
);
