import getMessage from "../../../../../../utils/getMessage";

const CommentUserIcon = ({authorRole, userSeesContent}) => {

  let frameClass;
  let icon;
  if (userSeesContent) {
    switch (authorRole) {
      case 'admin': icon = '👑'; break;
      default: icon = '😀'; break;
    }
    frameClass = `${authorRole}-icon`;
  } else {
    icon = ' ';
    frameClass = 'hidden-icon'
  }

  return (
    <div style={{display: 'flex', placeItems: 'center'}} aria-label={getMessage(authorRole)}>
      <div className={`hearing-comment-user-icon ${frameClass}`} aria-hidden="true">
        <div>
          <span>{icon}</span>
        </div>
      </div>
    </div>
  )
}

export default CommentUserIcon;