import LoadSpinner from "./LoadSpinner";

/** Covers the content with an overlay and a 
 * LoadSpinner when the provided request is truthy */
const LoadSpinnerContainer = ({isLoading, children}) => {

  return (
    <div style={{position: 'relative', display: 'inline-block'}}>
      {children}
      {isLoading && (
        <div style={{
          position: 'absolute', 
          width: '100%', height: '100%', 
          top: 0, left: 0,
          backgroundColor: 'rgba(255,255,255,0.5)'}}>
          <LoadSpinner style={{padding: 0}}/>
        </div>
      )}
    </div>
  )
}

export default LoadSpinnerContainer;