import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavItem} from "react-bootstrap";
import config from '../../config';
import { withRouter} from "react-router-dom";
import {stringify} from 'qs';


import getMessage from "../../utils/getMessage";
import { LinkContainer } from 'react-router-bootstrap';

const LanguageSwitcher = ({
  history,
  location,
}) => {

  const getChangedLanguagePath = (location, nextLang) => {
    const languageParam = stringify({lang: nextLang});
    let searchParams;
    if (location.search.includes('lang=')) {
      searchParams = location.search.replace(/lang=\w{2}/, languageParam);
    } else if (location.search) {
      searchParams = location.search + `&${languageParam}`;
    } else {
      searchParams = `?${languageParam}`
    }
    return `${location.pathname}${searchParams || languageParam}`
  }

  const newLanguage = () => {
    const languageIndex = config.languages.findIndex((lang) => (lang == config.activeLanguage));
    const nextLanguage = config.languages[ (languageIndex+1) % config.languages.length ];
    return nextLanguage;
  }
  return (
    <LinkContainer to={getChangedLanguagePath(location, newLanguage())}>
      <NavItem
        aria-label={getMessage(`lang-${newLanguage()}`)}
        lang={newLanguage()}
        className="language-button nav-item nav-link"
        href="#"
      >
      <span className="language-title" lang={newLanguage()}>{getMessage(`lang-${newLanguage()}`)}</span>
      </NavItem>
    </LinkContainer>
  );
}

LanguageSwitcher.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};
export { LanguageSwitcher as UnconnectedLanguageSwitcher};
export default withRouter(connect()(LanguageSwitcher));
