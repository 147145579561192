import config from "../config";
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return "";
}

export const logout = async (language = 'fi') => {
  try {
    await fetch(`${config.apiBaseUrl}/oidc/logout/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'x-csrftoken': getCookie('kkdev-csrftoken'),
      },
    });
  } catch (e) {
    console.error(e)
  }
  try {
    const token = localStorage.getItem('token');
    await fetch(`${config.apiBaseUrl}/v1/auth/logout/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Token " + token,
      },
    });
    localStorage.removeItem('token');
  } catch (e) {
    throw new Error('Couldn\'t log out.');
  }
  const addLanguageToUrl = (toString) => {
    return toString.includes('?')
      ? toString + `&lang=${language}`
      : toString + `?lang=${language}`
  }
  window.open(addLanguageToUrl('/'), '_self');
}