import HearingInformationDates from './HearingInformationDates';
import HearingInformationCommentCount from './HearingInformationCommentCount';

const HearingCardInformation = ({hearing, history}) => {


  return (
    <div className="hearing-card-comments">
      <div>
        <HearingInformationCommentCount hearing={hearing} justNumber={false} />
      </div>
      <HearingInformationDates hearing={hearing} />
    </div>
  );

}

export default HearingCardInformation;