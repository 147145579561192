import { useState } from "react";
import getMessage from "../../utils/getMessage";
import LoadSpinnerContainer from "../LoadSpinnerContainer";
import config from "../../config";
import { FormattedMessage } from "react-intl";
import { localizedNotifyError, localizedNotifySuccess } from "../../utils/notify";
import { turnResponseErrorStringToLocalizationKey } from "../../utils/validation";
import { updateCurrentUserWithPartial } from "../../store/actions/user";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import DigiraatiUsernameInput, { DigiraatiUsernameInputValidationSchema } from "../../views/Auth/Fields/UsernameInput";
import { object } from "yup";

const UserUpdateUsername = ({updateCurrentUserWithPartial}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changedSuccessfully, setChangedSuccessfully] = useState(false);

  const onSubmitUsername = async (values, setFieldError) => {
    setIsSubmitting(true);
    const sentUsername = values['username'];
    const token = localStorage.getItem("token");
    const response = await fetch(`${config.apiBaseUrl}/v1/users/current/`, {
      method: "PATCH",
      headers: {
        Authorization: "Token " + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: sentUsername,
      }),
    });
    if (response.ok) {
      updateCurrentUserWithPartial({username: sentUsername})
      localizedNotifySuccess('userUpdateUsernameSuccess')
      setChangedSuccessfully(true);
    } else {
      try {
        const data = await response.json();
        if (data.username) {
          const errorKey = turnResponseErrorStringToLocalizationKey(data.username[0], 'userUpdateUsernameError');
          localizedNotifyError(errorKey);
          setFieldError('username', errorKey)
        } else if (data.status) {
          const errorKey = turnResponseErrorStringToLocalizationKey(data.status, 'userUpdateUsernameError');
          localizedNotifyError(errorKey);
        } else {
          localizedNotifyError('userUpdateUsernameError');
        }
      } catch (_e) {
        localizedNotifyError('userUpdateUsernameError');
      }
    }
    setIsSubmitting(false);
  }

  return (
    <div className="hds-form hds-form--login" style={{paddingTop: '0'}}>
      <h3>
        <FormattedMessage id="userUpdateUsername" />
      </h3>
      {!changedSuccessfully && <div>
      <fieldset className="hds-fieldset">
        <p>
          <FormattedMessage id="userUpdateUsernameDescription" />
        </p>
          <Formik
            initialValues={{username:''}}
            validationSchema={object().shape({...DigiraatiUsernameInputValidationSchema})}
            >
              {({
                values,
                isValid,
                onSubmit = () => {},
                setFieldError,
              }) => (
                <Form>
                  <Field
                    name="username"
                    required
                    disabled={isSubmitting}
                    component={DigiraatiUsernameInput}
                    labelKey={"userUpdateUsernameLabel"}
                  />
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <LoadSpinnerContainer isLoading={isSubmitting}>
                      <button
                        type="button"
                        onClick={() => onSubmitUsername(values, setFieldError)}
                        className="digi-button"
                        disabled={!isValid || isSubmitting}
                      >
                        {getMessage('submit')}
                      </button>
                    </LoadSpinnerContainer>
                  </div>
                </Form>
              )}
          </Formik>
      </fieldset>
      
      </div>}
      {changedSuccessfully && <p><FormattedMessage id="userUpdateUsernameSuccess" /></p>}
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  updateCurrentUserWithPartial: (toUpdate) => {
    dispatch(updateCurrentUserWithPartial(toUpdate));
  },
});

export default connect(
  ()=>({}),
  mapDispatchToProps
)(UserUpdateUsername);