import { FormHelperText } from "@mui/material";
import getMessage from "../../../utils/getMessage";
import { string } from "yup";

export const DigiraatiUsernameInputValidationSchema = {
  username: string()
  .required('required')
  .matches(/^[a-zA-ZäÄöÖåÅ0-9@\.\+\-\_]+$/, 'usernameRegex'),
}

/** For use as a Formik Field component */
const DigiraatiUsernameInput = ({
  field,
  form,
  labelKey='username',
}) => {
  const {touched, errors} = form;
  
  return ( 
    <div className="hds-text-input">
      <label htmlFor="username">{getMessage(labelKey)}*</label>
      <input
        id="username"
        required
        name="username"
        className="form-control"
        type="text"
        autoComplete="new-password"
        disabled={form.isSubmitting}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        maxLength="150"
        aria-describedby="username_input_helpertext"
      />
      <FormHelperText
        id="username_input_helpertext"
        error={Boolean(touched.username && errors.username)}
        sx={{font: 'normal normal normal 18px PT Sans'}}
      >
        {touched.username && errors.username
          ? getMessage(errors.username)
          : ' '}
      </FormHelperText>
    </div>
  )
}

export default DigiraatiUsernameInput;