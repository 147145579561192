import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { getCurrentUser } from './store/actions/user';
import CloseButton from './components/CloseButton';
import CookieBar from './components/cookieBar/CookieBar';
import Footer from './components/Footer';
import Header from './components/Header/Header';
import config from './config';
import messages from './i18n';
import Routes from './routes';
import { checkCookieConsent } from './utils/cookieUtils';
import getMessage from './utils/getMessage';
import { checkHeadlessParam } from './utils/urlQuery';
import TimeoutModal from './components/TimeoutModal';
import { fetchLabels } from './store/actions';
import MissingInformation from './views/Auth/MissingInformation';
import Login from './views/Auth/Login';
import { logout } from './utils/loginUtils';

const App = ({
               language,
               getCurrentUser,
               isHighContrast,
               match,
               location,
               history,
               extendSession,
               userState,
             }) => {
  const [isTimeoutModalOpen, setIsTimeoutModalOpen] = useState(false);
  const [userActionRequired, setUserActionRequired] = useState(false);
  const user = userState?.data;

  const isPrivacyLocation = location.pathname === '/privacy' || location.pathname === '/terms-of-use-and-moderation';

  const timeToShowAboutToExpireAlertInMs = 30 * 60 * 1000;
  const tokenExpirationTimeInMs = 45 * 60 * 1000;

  useEffect(() => {
    config.activeLanguage = language; // for non react-intl localizations
    checkCookieConsent();
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (user?.missing_information?.length > 0) {
      setUserActionRequired(true);
    } else {
      setUserActionRequired(false);
    }
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem('token')) {
        const lastApiCall = localStorage.getItem('lastApiCall');
        if (!lastApiCall) {
          return;
        }
        const sinceThen = Date.now() - lastApiCall;
        if (sinceThen > timeToShowAboutToExpireAlertInMs) {
          setIsTimeoutModalOpen(true);
          if (sinceThen > tokenExpirationTimeInMs) {
            logout(language);
          }
        }
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  });

  const locale = language;
  const contrastClass = classNames({
    'high-contrast': isHighContrast,
  });
  const favlinks = [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicon/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png',
    },
    { rel: 'manifest', href: '/favicon/manifest.json' },
    {
      rel: 'mask-icon',
      href: '/favicon/safari-pinned-tab.svg',
      color: '#0072c6',
    },
    {
      rel: 'shortcut icon',
      type: 'image/x-icon',
      href: '/favicon/favicon.ico',
    },
  ];
  const favmeta = [
    { name: 'msapplication-config', content: '/favicon/browserconfig.xml' },
    { name: 'theme-color', content: '#ffffff' },
  ];
  const fullscreen = match.params.fullscreen === 'true';
  const headless = checkHeadlessParam(location.search);

  let header = null;
  if (!fullscreen && !headless) {
    header = (
      <Header
        slim={history.location.pathname !== '/'}
        history={history}
      />
    );
  }
  return (
    <IntlProvider locale={locale} messages={messages[locale] || {}}>
      <div className={contrastClass}>
        {config.showCookiebar && <CookieBar />}
        <a
          href="#main-container"
          onClick={() => document.getElementById('main-container').focus()}
          className="skip-to-main-content"
        >
          <FormattedMessage id="skipToMainContent" />
        </a>
        <div className="main-container">
          <Helmet
            titleTemplate={'%s - ' + getMessage('#appName')}
            defaultTitle={getMessage('#appName')}
            link={favlinks}
            meta={favmeta}
          >
            <html lang={locale} />
          </Helmet>
          {header}
          <main
            className={fullscreen ? 'fullscreen' : 'main-content'}
            id="main-container"
            tabIndex="-1"
          >
            {
              userActionRequired && !isPrivacyLocation
                ? <MissingInformation />
                : <Routes />
            }
          </main>
          <TimeoutModal
            isOpen={isTimeoutModalOpen}
            onExtendSession={() => {
              extendSession();
              setIsTimeoutModalOpen(false);
            }}
            close={() => setIsTimeoutModalOpen(false)}
          />
          <Footer language={locale} />
        </div>
        <ToastContainer
          bodyClassName="toast-container"
          closeButton={<CloseButton />}
        />
      </div>
    </IntlProvider>
  );
};

const mapStateToProps = state => ({
  language: state.language,
  isHighContrast: state.accessibility.isHighContrast,
  userState: state.user,
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: () => {
    dispatch(getCurrentUser());
  },
  extendSession: () => dispatch(fetchLabels()),
});

App.propTypes = {
  getCurrentUser: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  language: PropTypes.string,
  location: PropTypes.object,
  isHighContrast: PropTypes.bool,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
